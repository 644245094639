<template>
  <div>
    <v-img
      style="margin: auto; max-width: 500px; padding: 10px; margin-top: 10%;"
      :src="image"
    ></v-img>
    <div style="text-align: center;">
      <h1>404 Page Not Found</h1>
    </div>
  </div>
</template>

<script>
export default {
    computed: {
      image() {
        const images = ["error_jpekyd", "oh_dear_g6v96u", "ghost_pmw8gh", "aheh_eord7e", "lies_itnjpp"];
        return `https://res.cloudinary.com/breezy-software/image/upload/v1659060308/assets/${images[Math.floor(Math.random() * images.length)]}.png`
      }
    }
}
</script>
